import React from "react"
import createTemplate from "../../create-template"
import { useDialogState, Dialog, DialogBackdrop } from "reakit/Dialog"

import { useComponents } from "../../components"

function Biltmore({ styles, editing, elements, ...props }) {
  const Elements = useComponents()
  const checkboxes_color = styles.forElement("form_heading").color
  const customFieldCount = elements.custom.length
  const stackedDisplay = customFieldCount > 0

  const dialog = useDialogState()

  let isOdd = customFieldCount % 2 == 0 ? true : false

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
        className="formkit-bg-color"
      />
      <div
        style={styles.forElement("background")}
        className="formkit-bg-image"
      />
      <div className="formkit-wrapper">
        <header className="formkit-header">
          <Elements.Heading
            className="formkit-header-heading"
            name="heading"
            defaults={{ content: "Exercise & Empowerment" }}
          />
          <Elements.Region className="formkit-header-content" name="content">
            <Elements.Content
              defaults={{
                content:
                  "Watch Luis' popular inspiring talk, Exercise & Empowerment, and subscribe to download an exclusive resource that will help you create your own exercise and empowerment plan."
              }}
            />
          </Elements.Region>
        </header>
        <main>
          <div className="formkit-flex">
            <div
              className="formkit-form-container"
              style={styles.forElement("form_background")}
            >
              <Elements.Heading
                className="formkit-form-heading"
                name="form_heading"
                tag="h2"
                defaults={{ content: "Start exercising to empowerment today" }}
              />
              <Elements.Form data-stacked={stackedDisplay}>
                <Elements.Errors />
                <Elements.CustomFields
                  data-stacked={stackedDisplay}
                  data-odd={isOdd}
                  className="formkit-fields"
                  style={{
                    color: checkboxes_color
                  }}
                >
                  {stackedDisplay && <Elements.AddFieldButton />}
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Sign Up" }}
                  />
                </Elements.CustomFields>
                {!stackedDisplay && <Elements.AddFieldButton />}
              </Elements.Form>
              <Elements.Content
                className="formkit-disclaimer"
                name="disclaimer"
                defaults={{
                  content: "We respect your privacy. Unsubscribe at any time."
                }}
              />
            </div>
            <div className="formkit-video-container">
              <div className="formkit-background" />
              <Elements.Video
                name="video"
                className="formkit-video-main"
                defaults={{ src: "https://youtu.be/4vHu3lpzh9Q" }}
              />
              <svg
                className="formkit-play"
                onClick={dialog.show}
                width="156"
                height="156"
                viewBox="0 0 156 156"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ zIndex: 2 }}
              >
                <circle cx="78" cy="78" r="78" />
                <path
                  d="M66.5 59.0814L101 79L66.5 98.9186L66.5 59.0814Z"
                  strokeWidth="2"
                />
              </svg>
              {editing && (
                <button
                  onClick={dialog.show}
                  className="formkit-edit-button"
                  type="button"
                  style={{
                    background: "rgba(255,255,255,0.75)",
                    cursor: "pointer",
                    fontSize: 18,
                    border: "1px solid #ccc",
                    borderRadius: "3px",
                    padding: "8px",
                    outline: 0,
                    width: "200px",
                    position: "absolute",
                    top: "calc(50% + 100px)",
                    left: "calc(50% - 100px)",
                    zIndex: 2
                  }}
                >
                  Click to edit the video
                </button>
              )}
            </div>
          </div>
          <div className="formkit-content-wrapper">
            <Elements.Heading
              className="formkit-content-heading"
              name="content_heading"
              tag="h2"
              defaults={{ content: "Luis' story" }}
            />
            <div className="formkit-content-container">
              <Elements.Region
                className="formkit-content"
                group="content_body"
                name="content_left"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "As a professional athlete, Luis Mendez knows a thing or two about exercise. Since High School, Luis has trained to be a professional boxer, training and working out almost every day along the way."
                  }}
                />
              </Elements.Region>
              <Elements.Region
                className="formkit-content"
                group="content_body"
                name="content_right"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "Throughout this, Luis has seen the power of exercise in empowering you to take responsibility for yourself, feel strong, care for your mental health, and take a break from the stresses of life. This talk shares his thoughts on the topic."
                  }}
                />
              </Elements.Region>
            </div>
            <div className="formkit-bio">
              <Elements.Image
                className="formkit-image"
                name="image"
                defaults={{
                  src:
                    "https://embed.filekitcdn.com/e/2owDSJCEZcjwZhVLK4rMrK/dL9CpSnb4tcQzzwzYTy5o"
                }}
              />
              <Elements.Heading
                className="formkit-bio-heading"
                name="bio_heading"
                tag="h2"
                defaults={{ content: "Luis Mendez" }}
              />
              <Elements.Region
                className="formkit-bio-content"
                name="bio_content"
              >
                <Elements.Content
                  defaults={{
                    content: "Athlete, speaker, influencer."
                  }}
                />
              </Elements.Region>
              <Elements.BuiltWith background="background" image="background" />
            </div>
          </div>
          <DialogBackdrop
            {...dialog}
            className="formkit-overlay"
            style={{ position: editing ? "absolute" : "fixed" }}
          >
            <div className="formkit-modal-container">
              <Dialog
                {...dialog}
                className="formkit-dialog"
                unstable_portal={false}
                {...(editing
                  ? { modal: false, hideOnClickOutside: false }
                  : {})}
              >
                <Elements.Video
                  id="video"
                  name="video"
                  className="formkit-modal-video"
                  defaults={{
                    src: "https://youtu.be/4vHu3lpzh9Q"
                  }}
                />
              </Dialog>
              <button className="formkit-modal__close" onClick={dialog.hide}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="times"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    style={styles.forElement("close_button")}
                    fill="currentColor"
                    d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                  />
                </svg>
              </button>
            </div>
          </DialogBackdrop>
        </main>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Biltmore, { name: "Biltmore" })
